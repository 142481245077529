/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { Button, ProgressBar, makeStyles } from '@fluentui/react-components'
import { DemandAnalysis, DemandAnalysisStatus } from '../../api/schemas/schema'
import { useForm } from 'react-hook-form'
import { handleMessage } from '../../helpers/stateHelper'
import { api } from '../../helpers/apiHelper'
import { useMsal } from '@azure/msal-react'
import { Grid } from '../common/grid/Grid'
import { GridItem } from '../common/grid/GridItem'
import { ControlledTextInput, TextInput } from '../common/inputs/TextInput'
import { ControlledDatePicker } from '../common/inputs/DatePicker'
import { Modal, ModalActions } from '../common/modal/Modal'
import { getDefaultValues } from '../../helpers/demandAssessmentHelper'
import { Text } from '../common/text/Text'
import { useNavigate } from 'react-router-dom'
import { ControlledSimpleDropdown } from '../common/inputs/Dropdown'
import { IComplianceState } from '../../interfaces/IComplianceState'
import { DataContext } from '../../context/DataContext'
import { t } from '../../i18n/i18n'

type DemandAnalysisFormProps = {
   setComplianceState: React.Dispatch<React.SetStateAction<IComplianceState>>
   complianceState: IComplianceState
}
const useStyles = makeStyles({
   btnRight: { justifyContent: 'end' },
})
export const DemandAnalysisForm = ({ setComplianceState, complianceState }: DemandAnalysisFormProps) => {
   const dataContext = useContext(DataContext)
   const { branch, accessToken, userAccount } = dataContext.state
   const { setRootState } = dataContext
   const { accounts } = useMsal()
   const classes = useStyles()
   const demandAnalysisForm = useForm<DemandAnalysis>({
      defaultValues: getDefaultValues(dataContext, complianceState),
   })
   const navigate = useNavigate()
   const {
      control,
      reset,
      trigger,
      getValues,
      handleSubmit,
      formState: { isSubmitting, isValid, isSubmitSuccessful, isSubmitted },
   } = demandAnalysisForm
   const { leadershipPerspectives, processes, open, subjects } = complianceState

   const [error, setError] = useState<string>(null)

   const onSubmit = async (data: DemandAnalysis) => {
      trigger()
      if (isValid) {
         try {
            data.createdBy = userAccount
            data.modifiedBy = userAccount
            data.createdById = userAccount.id
            data.modifiedById = userAccount.id
            setComplianceState((prev) => ({ ...prev, open: false }))
            const demandAnalysis: DemandAnalysis = (await api(accessToken).addDemandAnalysis(data)).data
            reset(demandAnalysis, { keepIsSubmitSuccessful: true, keepIsSubmitted: true })
         } catch (error: any) {
            setError(error.error.message)
            handleMessage(setRootState, 'error', t('SomethingWentWrong'), error.error.message)
         }
      }
   }
   useEffect(() => {
      if (complianceState.demandAnalysisStatuses.length > 0) {
         reset(
            {
               status: DemandAnalysisStatus.Ongoing,
            },
            { keepDefaultValues: true, keepValues: true }
         )
      }
   }, [complianceState.demandAnalysisStatuses.length])
   const continuej = () => {
      setComplianceState((prev) => ({ ...prev, demandAnalyses: [...prev.allDemandAnalyses, getValues()] }))
      navigate('/compliance/' + getValues().id)
      reset(getDefaultValues(dataContext, complianceState))
   }

   useEffect(() => {
      if (isSubmitted) {
         if (!isSubmitSuccessful) {
            setComplianceState((prev) => ({ ...prev, open: true }))
         }
      }
   }, [isSubmitted])
   return (
      <>
         <Modal
            modalType="alert"
            title={
               isSubmitting ? t('CreatingDemandAssessment') : error ? t('CouldNotCreateItem', { Item: t('DemandAssessment') }) : t('DemandAssessmentCreated')
            }
            open={isValid && (isSubmitting || isSubmitSuccessful)}
         >
            {isSubmitting ? (
               <>
                  <ProgressBar />
                  <Text>{t('ThisCanTakeAMoment')}</Text>
               </>
            ) : error ? (
               <Text>
                  {t('ErrorMessage')}: {error}
               </Text>
            ) : (
               <Text>{t('ClickContinueToGoToAssessment')}</Text>
            )}
            <ModalActions className={classes.btnRight}>
               {error ? (
                  <Button onClick={() => reset(getDefaultValues(dataContext, complianceState))}>{t('Close')}</Button>
               ) : isSubmitSuccessful ? (
                  <Button appearance="primary" onClick={continuej}>
                     {t('Continue')}
                  </Button>
               ) : null}
            </ModalActions>
         </Modal>
         <Modal title={t('NewDemandAssessment')} modalType="modal" open={open}>
            <form onSubmit={handleSubmit(onSubmit)}>
               <Grid gap="L">
                  <GridItem size="12/12">
                     <ControlledTextInput required control={control} name={'name'} label={t('AssessmentName')} />
                  </GridItem>
                  <GridItem size="12/12">
                     <TextInput disabled required value={accounts[0].name} label={t('Owner')} />
                  </GridItem>
                  <GridItem size="12/12">
                     <TextInput disabled={true} value={branch.name} label={t('Branch')} />
                  </GridItem>
                  <GridItem size="12/12">
                     <ControlledSimpleDropdown
                        required
                        name={'leadershipPerspectives'}
                        control={control}
                        label={t('LeadershipPerspective')}
                        options={leadershipPerspectives.map((x) => x.text)}
                     />
                  </GridItem>
                  <GridItem size="12/12">
                     <ControlledSimpleDropdown required name={'processes'} control={control} label={t('Process')} options={processes.map((x) => x.text)} />
                  </GridItem>
                  <GridItem size="12/12">
                     <ControlledSimpleDropdown required name={'subjects'} control={control} label={t('Subject')} options={subjects.map((x) => x.text)} />
                  </GridItem>
                  {/* <GridItem size="12/12">
                     <Controller
                        name={'dataClassification'}
                        control={control}
                        rules={{ required: t('RequiredField') }}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                           <Field label={t('DataClassification')} required>
                              <Dropdown
                                 disabled
                                 value={value}
                                 selectedOptions={[value]}
                                 onOptionSelect={(e, d) => {
                                    onChange(d.optionValue)
                                 }}
                                 style={{ minWidth: 'auto' }}
                              />
                           </Field>
                        )}
                     />
                  </GridItem> */}
                  <GridItem size="6/12">
                     <ControlledDatePicker control={control} name={'analysisStart'} label={t('StartOfAssessment')} />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledDatePicker control={control} name={'analysisEnd'} label={t('EndOfAssessment')} />
                  </GridItem>
                  <GridItem size="12/12">
                     <ControlledTextInput multiline control={control} name={'comment'} label={t('Comment')} />
                  </GridItem>
                  <GridItem size="12/12">
                     <ModalActions>
                        <Button
                           onClick={() => {
                              setComplianceState((prev) => ({ ...prev, open: false }))
                              reset(getDefaultValues(dataContext, complianceState))
                           }}
                           appearance="secondary"
                        >
                           {t('Cancel')}
                        </Button>
                        <Button type="submit" appearance="primary" disabled={isSubmitting}>
                           {t('CreateAssessment')}
                        </Button>
                     </ModalActions>
                  </GridItem>
               </Grid>
            </form>
         </Modal>
      </>
   )
}
