import React from 'react'
import { Checkbox, Dropdown, Option, Divider } from '@fluentui/react-components'
import { t } from '../../../../i18n/i18n'
import { Controller, UseFormReturn } from 'react-hook-form'
import { GridItem } from '../../../common/grid/GridItem'
import { AccountPicker } from '../../../common/inputs/AccountPicker'
import { ControlledTextInput } from '../../../common/inputs/TextInput'
import { ActionPlan, DemandAnalysisQuestion } from '../../../../api/schemas/schema'
import { ParentQuestionInformation } from './ParentQuestionInformation'
import { Field } from '../../../common/inputs/Field'

type ActionDecisionProps = {
   actionPlanForm: UseFormReturn<ActionPlan, any, undefined>
   parent: DemandAnalysisQuestion
}
export const ActionDecision = ({ actionPlanForm, parent }: ActionDecisionProps) => {
   const { control, setValue, watch } = actionPlanForm

   return (
      <>
         <ParentQuestionInformation parent={parent} actionPlan={watch()} />
         <GridItem size="12/12">
            <Divider appearance="brand" />
         </GridItem>
         <GridItem size="12/12">
            <Controller
               control={control}
               name="actionNeeded"
               render={({ field: { value, onChange } }) => (
                  <Checkbox
                     size="large"
                     label={t('NoActionShallBeTaken')}
                     checked={value === false}
                     onChange={(e, data) => {
                        const actionNeeded = !value
                        if (!actionNeeded) {
                           setValue('decidedAction', null)
                           setValue('responsible', null)
                           setValue('responsibleId', null)
                           setValue('priority', null)
                        }
                        onChange(actionNeeded)
                     }}
                  />
               )}
            />
         </GridItem>
         {watch('actionNeeded') ? (
            <>
               <GridItem size="12/12">
                  <ControlledTextInput control={control} name={'decidedAction'} required label={t('DecisionOnAction')} multiline />
               </GridItem>
               <GridItem size="6/12">
                  <AccountPicker label={t('ResponsibleOfAction')} control={control} name={'responsible'} setValue={setValue} required />
               </GridItem>
               <GridItem size="6/12">
                  <Controller
                     control={control}
                     name="priority"
                     render={({ field: { value, onChange } }) => (
                        <Field label={t('Priority')} required>
                           <Dropdown value={value ? value.toString() : ''} onOptionSelect={(e, d) => onChange(Number(d.optionValue))}>
                              <Option>1</Option>
                              <Option>2</Option>
                              <Option>3</Option>
                              <Option>4</Option>
                           </Dropdown>
                        </Field>
                     )}
                  />
               </GridItem>
            </>
         ) : null}
         <GridItem size="12/12">
            <ControlledTextInput control={control} name={'decidedActionComment'} label={t('Comment')} multiline />
         </GridItem>
      </>
   )
}
