import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Dashboard } from './pages/Dashboard'
import { Compliance } from './pages/Compliance'
import { SelectedDemandAssessment } from './pages/SelectedDemandAssessment'
import { Settings } from './pages/Settings'
import { RiskManagement } from './pages/RiskManagement'
import { SelectedRiskAnalysis } from './pages/SelectedRiskAnalysis'

export const Router = () => {
   const router = createBrowserRouter([
      {
         path: '/',
         element: <Dashboard />,
      },
      {
         path: '/compliance',
         element: <Compliance />,
      },
      {
         path: '/compliance/:id',
         element: <SelectedDemandAssessment />,
      },
      {
         path: '/risk-management',
         element: <RiskManagement />,
      },
      {
         path: '/risk-management/:id',
         element: <SelectedRiskAnalysis />,
      },
      {
         path: '/settings',
         element: <Settings />,
      },
   ])
   return <RouterProvider router={router} />
}
