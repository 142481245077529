import React from 'react'
import { DatePicker } from '@fluentui/react-datepicker-compat'
import { Field } from './Field'
import { Control, Controller } from 'react-hook-form'
import { t } from '../../../i18n/i18n'
import dayjs from 'dayjs'
type ControlledDatePickerProps = {
   name: any
   control: Control<any>
   label: string
   disabled?: boolean
   required?: boolean
}
export const ControlledDatePicker = ({ name, control, label, disabled, required }: ControlledDatePickerProps) => {
   return (
      <Controller
         name={name}
         control={control}
         rules={{ required: required ? t('RequiredField') : false }}
         render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Field label={label} required={required ?? false} validationMessage={error ? error.message : null} validationState={error ? 'error' : null}>
               <DatePicker
                  strings={{
                     days: [t('Monday'), t('Tuesday'), t('Wednesday'), t('Thursday'), t('Friday'), t('Saturday'), t('Sunday')],
                     goToToday: t('GoToToday'),
                     months: [
                        t('January'),
                        t('February'),
                        t('March'),
                        t('April'),
                        t('May'),
                        t('June'),
                        t('July'),
                        t('August'),
                        t('September'),
                        t('October'),
                        t('November'),
                        t('December'),
                     ],
                     shortDays: [
                        t('SundayShort'),
                        t('MondayShort'),
                        t('TuesdayShort'),
                        t('WednesdayShort'),
                        t('ThursdayShort'),
                        t('FridayShort'),
                        t('SaturdayShort'),
                     ],
                     shortMonths: [t('Jan'), t('Feb'), t('Mar'), t('Apr'), t('May'), t('Jun'), t('Jul'), t('Aug'), t('Sep'), t('Oct'), t('Nov'), t('Dec')],
                  }}
                  style={{ width: '100%' }}
                  required={required ?? false}
                  today={new Date()}
                  allowTextInput
                  firstDayOfWeek={1}
                  value={value ? new Date(value) : null}
                  onSelectDate={onChange}
                  disabled={disabled ?? false}
                  formatDate={(date) => dayjs(date).format('YYYY-MM-DD')}
               />
            </Field>
         )}
      />
   )
}
