/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { Layout } from './Layout'
import { t } from '../i18n/i18n'
import { Button, Tab, makeStyles, tokens } from '@fluentui/react-components'
import { BookAdd24Regular } from '@fluentui/react-icons'
import { ComplianceTabs, getDemandAssessmentsColumns } from '../helpers/demandAssessmentHelper'
import { DemandAnalysisForm } from '../components/compliance/DemandAssessmentForm'
import { DemandAnalysisStatus } from '../api/schemas/schema'
import { api } from '../helpers/apiHelper'
import { handleMessage } from '../helpers/stateHelper'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Overview } from '../components/common/overview/Overview'
import { IComplianceState } from '../interfaces/IComplianceState'
import { Navigation } from '../components/common/navigation/Navigation'
import { BaseNavLinks } from '../components/common/navigation/BaseNavLinks'
import { DataContext } from '../context/DataContext'

const useStyles = makeStyles({
   largeBtn: {
      '@media screen and (max-width: 640px)': {
         display: 'none',
      },
   },
   phoneBtn: {
      display: 'none',
      '@media screen and (max-width: 640px)': {
         width: `calc(100% - (${tokens.spacingHorizontalL} * 2))`,
         display: 'inline-flex',
         position: 'fixed',
         bottom: '60px',
      },
   },
   row: {
      '& >': {
         ':nth-child(1)': {
            width: '50%',
         },
         ':nth-child(2)': {
            width: '20%',
         },
         ':nth-child(3)': {
            width: '30%',
         },
      },
   },
})

export const Compliance = () => {
   const classes = useStyles()
   const dataContext = useContext(DataContext)
   const { accessToken, organisation } = dataContext.state
   const [searchParams, setSearchParams] = useSearchParams()
   const selectedTab = searchParams.get('tab')

   const [complianceState, setComplianceState] = useState<IComplianceState>({
      loading: true,
      allDemandAnalyses: [],
      filteredDemandAnalyses: [],
      open: false,
      fetchDone: false,
      leadershipPerspectives: [],
      processes: [],
      subjects: [],
      demandAnalysisStatuses: [],
   })
   const navigate = useNavigate()
   useEffect(() => {
      if (!selectedTab) {
         setSearchParams({ tab: ComplianceTabs.Ongoing })
      }
      ;(async () => {
         const allDemandAnalyses = (await api(accessToken).getDemandAnalysisByOrganisationId(organisation.id)).data
         setComplianceState((prev) => ({ ...prev, allDemandAnalyses, loading: false }))
      })()
   }, [searchParams])
   useEffect(() => {
      if (selectedTab === ComplianceTabs.Ongoing)
         setComplianceState((prev) => ({ ...prev, filteredDemandAnalyses: prev.allDemandAnalyses.filter((x) => x.status !== DemandAnalysisStatus.Completed) }))
      if (selectedTab === ComplianceTabs.Concluded)
         setComplianceState((prev) => ({ ...prev, filteredDemandAnalyses: prev.allDemandAnalyses.filter((x) => x.status === DemandAnalysisStatus.Completed) }))
   }, [selectedTab, complianceState.allDemandAnalyses.length])

   const getNewAnalysisBtn = (showOnPhone: boolean) => {
      const onClick = () => setComplianceState((prev) => ({ ...prev, open: true }))
      if (showOnPhone)
         return (
            <Button className={classes.phoneBtn} icon={<BookAdd24Regular />} appearance="primary" onClick={onClick}>
               {t('NewDemandAssessment')}
            </Button>
         )
      else
         return (
            <Button className={classes.largeBtn} appearance="primary" onClick={onClick}>
               {t('NewDemandAssessment')}
            </Button>
         )
   }

   useEffect(() => {
      if (complianceState.open && !complianceState.fetchDone) {
         ;(async () => {
            try {
               Promise.all([
                  (await api(accessToken).getAllLeadershipPerspectives()).data,
                  (await api(accessToken).getAllProcesss()).data,
                  (await api(accessToken).getAllSubjects()).data,
               ]).then(([leadershipPerspectives, processes, subjects]) => {
                  setComplianceState((prev) => ({
                     ...prev,
                     leadershipPerspectives,
                     processes,
                     subjects,
                  }))
               })
            } catch (error: any) {
               handleMessage(dataContext.setRootState, 'error', t('FetchFailed'), error.message)
            }
         })()
      }
   }, [complianceState.open])

   return (
      <Layout breadCrumbItems={[{ title: t('ComplianceOfDemands'), active: true, path: '/compliance' }]}>
         <Navigation activeDesktopTab={'/compliance'} desktopNavLinks={<BaseNavLinks active="/compliance" />} />
         <Overview
            tabs={
               <>
                  <Tab value={ComplianceTabs.Ongoing}>{t('Ongoing')}</Tab>
                  <Tab value={ComplianceTabs.Concluded}>{t('Concluded')}</Tab>
               </>
            }
            button={(showInPhone: boolean) => getNewAnalysisBtn(showInPhone)}
            loading={complianceState.loading}
            columns={getDemandAssessmentsColumns()}
            items={complianceState.filteredDemandAnalyses}
            onRowClick={(item) => selectedTab !== ComplianceTabs.Concluded && navigate(`/compliance/${item.id}`)}
            keyValue="name"
            noItemsText={selectedTab === ComplianceTabs.Ongoing ? t('TimeToCreateYourFirstDemandAssessment') : t('NoConcludedDemandAssessments')}
            title={selectedTab === ComplianceTabs.Ongoing ? t('OngoingDemandAssessments') : t('ConcludedDemandAssessments')}
            rowClassName={classes.row}
         />

         <DemandAnalysisForm setComplianceState={setComplianceState} complianceState={complianceState} />
      </Layout>
   )
}
