import { TableColumnDefinition } from '@fluentui/react-components'
import { FieldArrayWithId } from 'react-hook-form'
import { ProtectedAsset } from '../api/schemas/schema'
import { t } from '../i18n/i18n'

export type ProtectedAssetFormValues = { asset: ProtectedAsset; protectedAssets: ProtectedAsset[] }

export const getProtectedAssetColumns = (): TableColumnDefinition<FieldArrayWithId<ProtectedAssetFormValues, 'protectedAssets', 'id'>>[] => [
   {
      columnId: 'name',
      renderHeaderCell: () => {
         return t('ProtectedAsset')
      },
      renderCell: (item: ProtectedAsset) => {
         return item.name
      },
      compare: (a, b) => {
         return a.name.localeCompare(b.name)
      },
   },
   {
      columnId: 'description',
      renderHeaderCell: () => {
         return t('Description')
      },
      renderCell: (item: ProtectedAsset) => {
         return item.description
      },
      compare: (a, b) => {
         return a.description.localeCompare(b.description)
      },
   },
]
export const getNewAsset = (name: string, branchId: string, organisationId: string): ProtectedAsset => ({
   createdBy: name,
   modifiedBy: name,
   branchId: branchId,
   organisationId: organisationId,
   processes: [],
   valuation: undefined,
})
