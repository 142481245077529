import { Input } from '@fluentui/react-components'
import { t } from '../../i18n/i18n'
import React, { useState } from 'react'
import { Control, Controller } from 'react-hook-form'
import '../common/inputs/resetInput.css'
import { Field } from '../common/inputs/Field'

type OrganisationNumberInputProps = {
   name: any
   control: Control<any>
   label?: string
   disabled?: boolean
   required?: boolean
}
export const OrganisationNumberInput = ({ name, control, label, disabled, required }: OrganisationNumberInputProps) => {
   const [shownValue, setShownValue] = useState<string>('')

   const formatZipCode = (value: string) => {
      const cleanedValue = value.replace(/\D/g, '') // Remove all non-numeric characters
      if (cleanedValue.length <= 6) {
         return cleanedValue // Return as is if 3 or fewer digits
      }
      return `${cleanedValue.slice(0, 6)}-${cleanedValue.slice(6, 10)}` // Format as "123 12"
   }

   return (
      <Controller
         name={name}
         control={control}
         rules={{ required: required ? t('RequiredField') : false, pattern: /^[0-9]+$/ }}
         render={({ field: { value, onChange }, fieldState: { error, invalid } }) => {
            return (
               <Field label={label} required={required ?? false} validationMessage={error ? error.message : null} validationState={error ? 'error' : null}>
                  <Input
                     type="text"
                     value={shownValue}
                     onChange={(e, data) => {
                        const rawValue = data.value.replace(/\D/g, '').slice(0, 10)

                        setShownValue(formatZipCode(rawValue))
                        onChange(rawValue)
                     }}
                     disabled={disabled ?? false}
                  />
               </Field>
            )
         }}
      />
   )
}
