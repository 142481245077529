import React, { useContext } from 'react'

import {
   makeStyles,
   tokens,
   Image,
   Button,
   shorthands,
   Menu,
   MenuTrigger,
   MenuPopover,
   MenuList,
   MenuItem,
   mergeClasses,
   Avatar,
} from '@fluentui/react-components'

import { SignOut24Regular, ChevronDown16Filled, Dismiss24Filled, Navigation24Filled, Organization24Regular, Alert20Regular } from '@fluentui/react-icons'
import { IDataContext } from '../../../interfaces/IDataContext'
import { useMsal } from '@azure/msal-react'
import { msalInstance } from '../../../Root'
import { DataContext } from '../../../context/DataContext'
import { t } from '../../../i18n/i18n'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles({
   header: {
      ...shorthands.padding(0, tokens.spacingHorizontalM, 0, tokens.spacingHorizontalM),
      width: '100vw',
      position: 'fixed',
      zIndex: 3,
      display: 'flex',
      justifyContent: 'space-between',
      boxSizing: 'border-box',
      height: '60px',
      backgroundColor: tokens.colorBrandBackground,
      boxShadow: tokens.shadow2,
      '& > *': {
         width: '33%',
      },
      '@media screen and (max-width: 1007px)': {
         boxShadow: tokens.shadow8Brand,
      },
   },
   image: {
      height: '50px',
      ...shorthands.margin(0, 'auto'),
      display: 'block',
      '@media screen and (max-width: 1007px)': {
         height: '60px',
      },
   },
   colorWhite: {
      color: tokens.colorNeutralForegroundInverted,
      '&:hover': {
         color: tokens.colorBrandForegroundInvertedHover,
      },
   },
   hideInPhone: {
      '@media screen and (max-width: 1007px)': {
         visibility: 'hidden',
      },
   },
   noneInPhone: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
   showOnPhone: {
      display: 'none',
      '@media screen and (max-width: 1007px)': {
         display: 'block',
      },
   },
   pointer: {
      cursor: 'pointer',
   },
   flex: {
      display: 'flex',
   },
   flexEnd: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      ...shorthands.gap(tokens.spacingHorizontalXXL),
      '@media screen and (max-width: 1007px)': {
         ...shorthands.gap(0),
      },
   },
   avatar: {
      marginRight: '20px',
      '@media screen and (max-width: 1007px)': { marginRight: 0 },
   },
})
export const Header = () => {
   const navigate = useNavigate()
   const data = useContext(DataContext)
   const { navigationExpanded, organisation } = data.state
   const { accounts } = useMsal()
   const classes = useStyles()
   const toggleNavigation = (data: IDataContext) => {
      data.setRootState((prev) => ({
         ...prev,
         navigationExpanded: !prev.navigationExpanded,
      }))
   }
   const getAlertBtn = (showOnPhone?: boolean): JSX.Element => (
      <Button
         className={mergeClasses(showOnPhone ? classes.showOnPhone : classes.noneInPhone, classes.colorWhite)}
         icon={<Alert20Regular />}
         appearance="transparent"
      />
   )
   return (
      <>
         <header className={classes.header}>
            <div className={classes.flex}>
               {getAlertBtn(true)}
               <Button
                  className={mergeClasses(classes.colorWhite, classes.hideInPhone, classes.pointer)}
                  icon={navigationExpanded ? <Dismiss24Filled /> : <Navigation24Filled />}
                  size="large"
                  appearance="transparent"
                  onClick={() => toggleNavigation(data)}
               />
            </div>
            <div>
               <Image alt={t('365Robust')} src="/365Robust.svg" className={mergeClasses(classes.image, classes.pointer)} onClick={() => navigate('/')} />
            </div>
            <div className={classes.flexEnd}>
               {organisation && (
                  <>
                     <Menu>
                        <MenuTrigger>
                           <Button
                              className={mergeClasses(classes.noneInPhone, classes.colorWhite, classes.pointer)}
                              appearance="transparent"
                              icon={<ChevronDown16Filled />}
                              iconPosition="after"
                           >
                              {organisation.name}
                           </Button>
                        </MenuTrigger>
                        <MenuPopover></MenuPopover>
                     </Menu>
                     <Menu>
                        <MenuTrigger>
                           <Button
                              className={mergeClasses(classes.showOnPhone, classes.colorWhite, classes.pointer)}
                              appearance="transparent"
                              icon={<Organization24Regular />}
                           >
                              <ChevronDown16Filled />
                           </Button>
                        </MenuTrigger>
                        <MenuPopover></MenuPopover>
                     </Menu>
                  </>
               )}
               {getAlertBtn(false)}
               <Menu>
                  <MenuTrigger>
                     <Avatar className={mergeClasses(classes.pointer, classes.avatar)} size={24} name={accounts[0].name} />
                  </MenuTrigger>
                  <MenuPopover>
                     <MenuList>
                        <MenuItem icon={<SignOut24Regular />} onClick={() => msalInstance.logoutRedirect()}>
                           {t('SignOut')}
                        </MenuItem>
                     </MenuList>
                  </MenuPopover>
               </Menu>
            </div>
         </header>
      </>
   )
}
