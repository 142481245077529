import React, { useContext } from 'react'
import { Field } from '../../common/inputs/Field'
import { Modal, ModalActions } from '../../common/modal/Modal'
import { ControlledTextInput } from '../../common/inputs/TextInput'
import { Button, Dropdown, Option, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { useForm } from 'react-hook-form'
import { Risk, RiskAnalysis } from '../../../api/schemas/schema'
import { DataContext } from '../../../context/DataContext'
import { t } from '../../../i18n/i18n'
import { IRiskAnalysisState } from '../../../interfaces/IRiskAnalysisState'
import { addRisk } from '../../../helpers/riskAnalysisHelper'
const useStyles = makeStyles({
   form: {
      display: 'flex',
      flexDirection: 'column',
      ...shorthands.gap(tokens.spacingHorizontalXL),
   },
})
type ModalAddRiskProps = {
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IRiskAnalysisState>>
   setOpen: React.Dispatch<React.SetStateAction<boolean>>
   open: boolean
   analysis: RiskAnalysis
}
export const ModalAddRisk = ({ setRiskAnalysisState, setOpen, open, analysis }: ModalAddRiskProps) => {
   const classes = useStyles()
   const dataContext = useContext(DataContext)
   const { protectedAssets } = dataContext.state

   const { control, setValue, handleSubmit, reset } = useForm<Risk>()
   const onSubmit = (data: Risk) => {
      addRisk(setRiskAnalysisState, close, dataContext, data, analysis)
   }
   const close = () => {
      reset()
      setOpen(false)
   }
   return (
      <Modal title={t('IdentifyRisk')} modalType="modal" open={open}>
         <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Field label={t('ProtectedAsset')} required>
               <Dropdown
                  onOptionSelect={(e, data) => {
                     setValue(
                        'protectedAsset',
                        protectedAssets.find((x) => x.id === data.optionValue)
                     )
                     setValue('protectedAssetId', data.optionValue)
                  }}
               >
                  {protectedAssets.map((x) => (
                     <Option value={x.id}>{x.name}</Option>
                  ))}
               </Dropdown>
            </Field>
            <ControlledTextInput control={control} name={'scenario'} label={t('RiskScenario')} multiline required />
            <ControlledTextInput control={control} name={'source'} label={t('RiskSource')} multiline required />
            <ControlledTextInput control={control} name={'currentState'} label={t('CurrentProtectionAndOrPotentialWeaknesses')} multiline required />
            <ControlledTextInput control={control} name={'consequense'} label={t('Consequense')} multiline required />
            <ControlledTextInput control={control} name={'underlyingMotivation'} label={t('UnderlyingMotivationAndStand')} multiline />
            <ModalActions>
               <Button onClick={close}>{t('Cancel')}</Button>
               <Button appearance="primary" type="submit">
                  {t('Save')}
               </Button>
            </ModalActions>
         </form>
      </Modal>
   )
}
