import React, { useEffect } from 'react'
import { Tab, TabList, makeStyles, mergeClasses } from '@fluentui/react-components'
import { InfoShield20Regular, SearchShield20Regular, Classification20Regular, TaskListSquareLtr20Regular } from '@fluentui/react-icons'
import { useSearchParams } from 'react-router-dom'
import { Information } from '../Information'
import { RiskAnalysis } from '../../../api/schemas/schema'
import { Identifying } from './Identifying'
import { IRiskAnalysisState } from '../../../interfaces/IRiskAnalysisState'
import { Layout } from '../../../pages/Layout'
import { t } from '../../../i18n/i18n'
const useStyles = makeStyles({
   header: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
   },
   flexOnlyLarge: {
      '@media screen and (max-width: 1007px)': { display: 'none' },
   },
})
type FRAProps = {
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IRiskAnalysisState>>
   analysis: RiskAnalysis
}
enum FRATabs {
   Info = 'info',
   Identifying = 'identifying',
   Assessment = 'assessment',
   ActionPlan = 'action-plan',
}
export const FRA = ({ setRiskAnalysisState, analysis }: FRAProps) => {
   const classes = useStyles()

   const [searchParams, setSearchParams] = useSearchParams()
   const selectedTab = searchParams.get('tab')
   const setTab = (tab: string) => {
      setSearchParams((prev) => ({ ...prev, tab }))
   }
   useEffect(() => {
      if (!selectedTab) {
         setTab('info')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   const getFRATitle = (tab: string): string => {
      switch (tab) {
         case FRATabs.Info:
            return t('Information')
         case FRATabs.Identifying:
            return t('RiskIdentifying')
         case FRATabs.Assessment:
            return t('RiskAnalysis/assessment')
         case FRATabs.ActionPlan:
            return t('RiskManagementPlan')
      }
   }
   return (
      <Layout
         title={getFRATitle(selectedTab)}
         breadCrumbItems={[
            { path: '/risk-management', active: false, title: t('RiskManagement') },
            { path: `/risk-management/${analysis.id}`, active: true, title: analysis.name },
         ]}
      >
         <header className={mergeClasses(classes.header, classes.flexOnlyLarge)}>
            <TabList selectedValue={selectedTab} onTabSelect={(e, data) => setTab(data.value as string)}>
               <Tab icon={<InfoShield20Regular />} value={FRATabs.Info}>
                  {getFRATitle(FRATabs.Info)}
               </Tab>
               <Tab icon={<SearchShield20Regular />} value={FRATabs.Identifying}>
                  {getFRATitle(FRATabs.Identifying)}
               </Tab>
               <Tab icon={<Classification20Regular />} value={FRATabs.Assessment}>
                  {getFRATitle(FRATabs.Assessment)}
               </Tab>
               <Tab icon={<TaskListSquareLtr20Regular />} value={FRATabs.ActionPlan}>
                  {getFRATitle(FRATabs.ActionPlan)}
               </Tab>
            </TabList>
         </header>
         {selectedTab === FRATabs.Info && <Information analysis={analysis} />}
         {selectedTab === FRATabs.Identifying && <Identifying analysis={analysis} setRiskAnalysisState={setRiskAnalysisState} />}
         {(selectedTab === FRATabs.Assessment || selectedTab === FRATabs.ActionPlan) && <>Under utveckling...</>}
      </Layout>
   )
}
