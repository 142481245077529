import React, { useContext, useEffect, useState } from 'react'
import { Button, DialogActions, DialogContent, DialogTitle, Dropdown } from '@fluentui/react-components'
import { Process, ProtectedAsset } from '../../api/schemas/schema'
import { useFieldArray, useForm } from 'react-hook-form'
import { DataContext } from '../../context/DataContext'
import { handleMessage } from '../../helpers/stateHelper'
import { api } from '../../helpers/apiHelper'
import { useMsal } from '@azure/msal-react'
import { GridItem } from '../common/grid/GridItem'
import { Grid } from '../common/grid/Grid'
import { t } from '../../i18n/i18n'
import { ControlledTextInput } from '../common/inputs/TextInput'
import { ControlledDatePicker } from '../common/inputs/DatePicker'
import { List } from '../common/list/List'
import { ProtectedAssetFormValues, getProtectedAssetColumns, getNewAsset } from '../../helpers/protectedAssetHelper'
import { Add24Regular } from '@fluentui/react-icons'
import { DropdownMulti } from '../common/inputs/DropdownMulti'
import { ControlledMoneyInput } from '../common/inputs/ControlledMoneyInput'
import { DialogBody } from '../common/modal/Modal'
import { Field } from '../common/inputs/Field'

type ProtectedAssetsProps = {
   nextStep: () => void
   dialogTitleClass: string
   dialogContentClass: string
}

export const ProtectedAssetsStep = ({ nextStep, dialogTitleClass, dialogContentClass }: ProtectedAssetsProps) => {
   const dataContext = useContext(DataContext)
   const { setRootState } = dataContext
   const { branch, organisation, accessToken } = dataContext.state
   const { accounts } = useMsal()

   const branchForm = useForm<ProtectedAssetFormValues>({
      defaultValues: { asset: getNewAsset(accounts[0].name, branch.id, organisation.id), protectedAssets: [] },
   })
   const {
      control,
      watch,
      reset,
      trigger,
      clearErrors,

      formState: { isValid },
   } = branchForm
   const { fields, append } = useFieldArray({ control, name: 'protectedAssets' })
   const [proccesses, setProccesses] = useState<Process[]>([])

   const onSubmit = async (data: ProtectedAsset[]) => {
      try {
         const addedAssets = (await api(accessToken).addProtectedAssetsAsync(data)).data
         setRootState((prev) => ({
            ...prev,
            protectedAssets: addedAssets,
         }))
      } catch (error: any) {
         handleMessage(setRootState, 'error', t('SomethingWentWrong'), error.message)
      }
   }
   useEffect(() => {
      ;(async () => {
         try {
            const process: Process[] = (await api(accessToken).getAllProcesss()).data

            setProccesses(process)
         } catch (error: any) {
            handleMessage(setRootState, 'error', t('FetchFailed'), error.message)
         }
      })()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   return (
      <form>
         <DialogBody>
            <DialogTitle action="" as="h3" className={dialogTitleClass}>
               {t('ProtectedAssets')}
            </DialogTitle>
            <DialogContent className={dialogContentClass}>
               <Grid gap="L">
                  <GridItem size="6/12">
                     <ControlledTextInput required control={control} name={'asset.name'} label={t('ProtectedAsset')} />
                  </GridItem>
                  <GridItem size="6/12">
                     <Field label={t('BranchAffiliation')}>
                        <Dropdown disabled value={branch.name} />
                     </Field>
                  </GridItem>
                  <GridItem size="12/12">
                     <ControlledTextInput multiline required control={control} name={'asset.description'} label={t('DescriptionOfAsset')} />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledMoneyInput control={control} name={'asset.valuation'} label={t('ProtectedAssetValuation')} />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledDatePicker control={control} name={'asset.valuationDate'} label={t('DateForValuation')} />
                  </GridItem>
                  <GridItem size="12/12">
                     <DropdownMulti
                        label={t('AccessAffectsFollowingProcesses')}
                        required
                        control={control}
                        items={proccesses}
                        modelName="process"
                        name={'asset.processes'}
                        parentItemId={watch('asset.id')}
                        parentItemType={'protectedAsset'}
                     />
                  </GridItem>
                  <GridItem size="12/12">
                     <ControlledTextInput multiline rows={2} control={control} name={'asset.motivation'} label={t('MotivationAndStand')} />
                  </GridItem>
                  <GridItem size="12/12">
                     <Button
                        style={{ float: 'right' }}
                        appearance="primary"
                        icon={<Add24Regular />}
                        onClick={() => {
                           if (!isValid) trigger()
                           if (isValid) {
                              clearErrors()
                              append(watch('asset'))
                              reset((prev) => ({ asset: getNewAsset(accounts[0].name, branch.id, organisation.id), protectedAssets: prev.protectedAssets }), {
                                 keepErrors: false,
                              })
                           }
                        }}
                     >
                        {t('Add')}
                     </Button>
                  </GridItem>

                  <GridItem size="12/12">
                     <List columns={getProtectedAssetColumns()} items={fields} title={t('AddedAssets')} titleAs={'h4'} />
                  </GridItem>
               </Grid>
            </DialogContent>
            <DialogActions>
               <Button appearance="primary" disabled={watch('protectedAssets').length === 0} onClick={() => onSubmit(watch('protectedAssets'))}>
                  {t('Finish')}
               </Button>
            </DialogActions>
         </DialogBody>
      </form>
   )
}
