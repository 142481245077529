import React, { useContext, useEffect, useState } from 'react'
import { Button, DialogActions, DialogContent, DialogTitle, Dropdown, Option } from '@fluentui/react-components'
import { Organisation, OrganisationSize, OrganisationType, SNI, UserAccount } from '../../api/schemas/schema'
import { Controller, useForm } from 'react-hook-form'
import { ControlledTextInput } from '../common/inputs/TextInput'
import { DataContext } from '../../context/DataContext'
import { handleMessage } from '../../helpers/stateHelper'
import { SniDropdown } from '../common/inputs/SniDropdown'
import { api } from '../../helpers/apiHelper'
import { useMsal } from '@azure/msal-react'
import { convertToDropdownOption } from '../../helpers/dropdownHelper'
import { GridItem } from '../common/grid/GridItem'
import { Grid } from '../common/grid/Grid'
import { Title } from '../common/text/Title'
import { t } from '../../i18n/i18n'
import { ControlledZipInput } from '../common/inputs/ControlledZipInput'
import { OrganisationNumberInput } from './OrganisationNumberInput'
import { DialogBody } from '../common/modal/Modal'
import { Field } from '../common/inputs/Field'

type OrganisationStepProps = {
   nextStep: () => void
   dialogTitleClass: string
   dialogContentClass: string
}
export const OrganisationStep = ({ nextStep, dialogTitleClass, dialogContentClass }: OrganisationStepProps) => {
   const dataContext = useContext(DataContext)
   const { userAccount, accessToken } = dataContext.state
   const { setRootState } = dataContext
   const { accounts } = useMsal()
   const organisationForm = useForm<Organisation>()
   const { control, setValue, handleSubmit, watch, reset } = organisationForm
   const [snis, setSnis] = useState<SNI[]>()
   const [organisationTypes, setOrganisationTypes] = useState<OrganisationType[]>([])
   const [organisationSizes, setOrganisationSizes] = useState<OrganisationSize[]>([])

   const isDisabled: boolean = false

   const onSubmit = async (data: Organisation) => {
      try {
         data.country = 'Sverige'
         data.createdBy = accounts[0].name
         data.modifiedBy = accounts[0].name
         const organisation: Organisation = (await api(accessToken).addOrganisation(data)).data
         userAccount.organisationId = organisation.id
         const updatedAccount: UserAccount = (await api(accessToken).updateUserAccount(userAccount)).data
         setRootState((prev) => ({
            ...prev,
            userAccount: updatedAccount,
            organisation,
         }))

         nextStep()
      } catch (error: any) {
         handleMessage(setRootState, 'error', t('SomethingWentWrong'), error.message)
      }
   }
   useEffect(() => {
      ;(async () => {
         try {
            if (userAccount.organisationId) reset((await api(accessToken).getOrganisationById(userAccount.organisationId)).data)
            Promise.all([
               await api(accessToken).getAllSnis(),
               await api(accessToken).getAllOrganisationTypes(),
               await api(accessToken).getAllOrganisationSizes(),
            ]).then(([sni, organisationTypes, organisationSizes]) => {
               setSnis(sni.data)
               setOrganisationTypes(organisationTypes.data)
               setOrganisationSizes(
                  organisationSizes.data.sort((a, b) => {
                     const valueA = a.text.includes('+') ? Number(a.text.split('+')[0]) : Number(a.text.split('-')[0])
                     const valueB = b.text.includes('+') ? Number(b.text.split('+')[0]) : Number(b.text.split('-')[0])
                     return valueA - valueB
                  })
               )
            })
         } catch (error: any) {
            handleMessage(setRootState, 'error', t('FetchFailed'), error.message)
         }
      })()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   return (
      <form onSubmit={handleSubmit(onSubmit)}>
         <DialogBody>
            <DialogTitle action="" as="h3" className={dialogTitleClass}>
               {t('WelcomeTo') + ' 365Robust!'}
            </DialogTitle>
            <DialogContent className={dialogContentClass}>
               <Grid gap="L">
                  <GridItem size="12/12">
                     <Title as="h4">{t('OrganisationalData')}</Title>
                  </GridItem>
                  <GridItem size="6/12">
                     <OrganisationNumberInput disabled={isDisabled} required control={control} name={'organisationNumber'} label={t('OrganisationNumber')} />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledTextInput disabled={isDisabled} required control={control} name={'name'} label={t('CompanyName')} />
                  </GridItem>
                  <GridItem size="6/12">
                     <Controller
                        control={control}
                        name="organisationSize.text"
                        rules={{ required: t('RequiredField') }}
                        render={({ field: { value }, fieldState: { error } }) => (
                           <Field label={t('OrganisationSize')} required validationMessage={error?.message} validationState={error ? 'error' : null}>
                              <Dropdown
                                 disabled={isDisabled}
                                 value={value ?? ''}
                                 onOptionSelect={(e, d) => {
                                    setValue('organisationSize.id', d.optionValue)
                                    setValue('organisationSize.text', d.optionText)
                                    setValue('organisationSizeId', d.optionValue)
                                 }}
                                 selectedOptions={[watch('organisationSize.id') ?? '']}
                                 style={{ minWidth: 'auto' }}
                              >
                                 {convertToDropdownOption(organisationSizes).map((x) => (
                                    <Option key={x.key} value={x.key.toString()} disabled={x.disabled ? x.disabled : false}>
                                       {x.text}
                                    </Option>
                                 ))}
                              </Dropdown>
                           </Field>
                        )}
                     />
                  </GridItem>
                  <GridItem size="6/12">
                     <Controller
                        control={control}
                        name="organisationType.text"
                        rules={{ required: t('RequiredField') }}
                        render={({ field: { value }, fieldState: { error } }) => (
                           <Field label={t('OrganisationType')} required validationMessage={error?.message} validationState={error ? 'error' : null}>
                              <Dropdown
                                 disabled={isDisabled}
                                 value={value ?? ''}
                                 onOptionSelect={(e, d) => {
                                    setValue('organisationType.id', d.optionValue)
                                    setValue('organisationType.text', d.optionText)
                                    setValue('organisationTypeId', d.optionValue)
                                 }}
                                 selectedOptions={[watch('organisationSize.id') ?? '']}
                                 style={{ minWidth: 'auto' }}
                              >
                                 {convertToDropdownOption(organisationTypes).map((x) => (
                                    <Option key={x.key} value={x.key.toString()} disabled={x.disabled ? x.disabled : false}>
                                       {x.text}
                                    </Option>
                                 ))}
                              </Dropdown>
                           </Field>
                        )}
                     />
                  </GridItem>
                  <GridItem size="12/12">
                     <SniDropdown disabled={isDisabled} required organisationForm={organisationForm} label={t('SNI')} items={snis ? snis : []} />
                  </GridItem>
                  <GridItem size="12/12">
                     <Title as="h4">{t('BillingData')}</Title>
                  </GridItem>
                  <GridItem size="12/12">
                     <ControlledTextInput disabled={isDisabled} required control={control} name={'invoiceReference'} label={t('InvoiceReference')} />
                  </GridItem>
                  <GridItem size="12/12">
                     <ControlledTextInput disabled={isDisabled} required control={control} name={'invoiceAddress'} label={t('InvoiceAddress')} />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledZipInput disabled={isDisabled} required control={control} name={'postalCode'} label={t('PostalCode')} />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledTextInput disabled={isDisabled} required control={control} name={'county'} label={t('County')} />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledTextInput type="tel" disabled={isDisabled} required control={control} name={'phoneNumber'} label={t('PhoneNumber')} />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledTextInput disabled={isDisabled} required control={control} name={'email'} type="email" label={t('Email')} />
                  </GridItem>
               </Grid>
            </DialogContent>
            <DialogActions>
               <Button type={'submit'} appearance="primary">
                  {t('Next')}
               </Button>
            </DialogActions>
         </DialogBody>
      </form>
   )
}
