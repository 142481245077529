import React from 'react'
import { Dropdown, Option, tokens } from '@fluentui/react-components'
import { Control, Controller } from 'react-hook-form'
import { t } from '../../../i18n/i18n'
import { CheckboxCheckedFilled } from '@fluentui/react-icons'
import { Text } from '@fluentui/react-components'
import { Field } from './Field'
type ControlledSimpleDropdownProps = {
   options: string[]
   name: any
   control: Control<any>
   label: string
   required?: boolean
}
export const ControlledSimpleDropdown = ({ options, name, control, label, required }: ControlledSimpleDropdownProps): JSX.Element => {
   const handleSelectAll = (selectedOptions: string[], onChange: Function) => {
      if (selectedOptions.length < options.length) {
         onChange(options)
      } else {
         onChange([])
      }
   }
   return (
      <Controller
         name={name}
         control={control}
         rules={{ required: required ? t('RequiredField') : false }}
         render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
               <Field label={label} required={required ?? false} validationMessage={error ? error.message : null} validationState={error ? 'error' : null}>
                  <Dropdown
                     onOptionSelect={(e, d) => {
                        if (d.selectedOptions.includes('all')) handleSelectAll(d.selectedOptions, onChange)
                        else onChange(d.selectedOptions)
                     }}
                     value={value && value.length > 0 && value.length === options.length ? t('All') : value?.join(', ')}
                     multiselect
                     selectedOptions={value}
                  >
                     <Option text={t('All')} checkIcon={value.length >= options.length && null} value="all">
                        <span style={{ position: 'relative', left: '-5px', display: 'flex' }}>
                           {value.length === options.length && <CheckboxCheckedFilled style={{ fontSize: '23px' }} color={tokens.colorBrandForeground1} />}
                           <Text style={{ marginLeft: value.length === options.length ? '2px' : '5px' }}> {t('All')}</Text>
                        </span>
                     </Option>
                     {options.map((x) => (
                        <Option>{x}</Option>
                     ))}
                  </Dropdown>
               </Field>
            )
         }}
      />
   )
}
